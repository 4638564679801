import React from 'react';
class About extends React.Component {
  componentDidMount() {
    window &&
      window.location.replace(
        'https://onlinesurvey.t-systems.com/survey/Inflight_Survey'
      );
  }
  render() {
    return <p>Page redirect...</p>;
  }
}

export default About;
